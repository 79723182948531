import React, { useContext, useEffect, useState } from 'react';
import {
  CustomSubNav,
  CustomTable,
  Loader,
  PageTitle,
  WhitelabelContext,
} from 'components/lib';
import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilListUl,
  UilUsersAlt,
  UilEnvelopeTimes,
  UilEnvelopeCheck,
  UilClock,
  UilTimes,
  UilEdit,
  UilEnvelopeOpen,
  UilLink,
  UilEnvelopeReceive,
  UilExternalLinkAlt,
  UilArrowBreak,
  UilHistory,
  UilInfoCircle
} from '@iconscout/react-unicons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import WarmUpChart from './warmupChart';
import { Col, Row } from 'react-grid-system';
import { Tooltip } from 'react-tooltip';

import './sequenceStats.scss';
import Percentage from 'components/percentage/Percentage';
import { Helmet } from 'react-helmet';

export default function SequenceStats() {
  const [sequence, setSequence] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [sent, setSent] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [outbox, setOutbox] = useState(0);
  const [opens, setOpens] = useState(0);
  const [linkClicks, setLinkClicks] = useState(0);
  const [replies, setReplies] = useState(0);
  const [bounces, setBounces] = useState(0);
  const [unsubscribed, setUnsubscribed] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigator = useNavigate();
  let { id } = useParams();
  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        fetchSequence();
        fetchSequenceStats();
      }, 5000);
    }
  }, [id]);

  useEffect(() => {
    if (sequence) {
      let cols = [
        {
          Header: 'Email Sender',
          accessor: 'sender',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            const { original } = row;
            if (!original.sender) return <div className="flex">
              <UilTimes
                size={16}
                style={{ marginTop: 4, marginRight: 3 }}
                color="#E53A40"
              />
              Sender was Removed
            </div>;

            return (
              <Link
                to={'/outreach/reports'}
                className="csCustomeLink text-[#3A3A3A]"
                state={{
                  sender: original.sender,
                  sequence: sequence,
                }}
              >
                {original.sender.alias ||
                  original.sender.microsoft_email ||
                  original.sender.google_email ||
                  original.sender.smtpImapEmail ||
                  'Sender Not Found'}
                <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </Link>
            );
          },
        },
        {
          Header: (
            <div className="flex csCenter">
              <UilUsersAlt size={18} style={{ marginTop: 2, marginRight: 3 }} />
              Leads
            </div>
          ),
          accessor: 'leads',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value}</span>;
          },
        },
        {
          Header: (
            <div className="flex csCenter">
              <UilClock size={18} style={{ marginTop: 2, marginRight: 3 }} />
              Scheduled
            </div>
          ),
          accessor: 'scheduled',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value}</span>;
          },
        },
        ...(sequence?.checkEmailBeforeSending
          ? [
              {
                Header: (
                  <div className="flex csCenter">
                    <UilEnvelope
                      size={18}
                      style={{ marginTop: 2, marginRight: 3 }}
                    />
                    Outbox
                  </div>
                ),
                accessor: 'outbox',
                disableSortBy: true,
                Cell: ({ row, value }) => {
                  return <span className="csCenter">{value}</span>;
                },
              },
            ]
          : []),
        {
          Header: (
            <div className="flex csCenter">
              <UilEnvelopeCheck
                size={18}
                style={{ marginTop: 2, marginRight: 3 }}
              />
              Sent
            </div>
          ),
          accessor: 'sent',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value}</span>;
          },
        },
        {
          Header: (
            <div className="flex csCenter">
              <UilEnvelopeOpen
                size={18}
                style={{ marginTop: 2, marginRight: 3 }}
              />
              Opens <UilInfoCircle size={18} style={{ marginTop: 2, marginRight: 3 }} className='ml-2' id='show-wrong-opens-reason' />
              <Tooltip anchorSelect='#show-wrong-opens-reason' clickable>Open tracking is not accurate (<a style={{ color: 'white' }} href='https://help.salesblink.io/en/articles/9080654-why-are-my-email-open-stats-wrong' target='_blank'>Learn Why</a>).</Tooltip>
            </div>
          ),
          accessor: 'opens',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value} <Percentage value={(value/row.original.sent)*100}/></span>;
          },
        },
        {
          Header: (
            <div className="flex csCenter">
              <UilLink size={18} style={{ marginTop: 2, marginRight: 3 }} />
              Link Clicks
            </div>
          ),
          accessor: 'link_clicks',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value} <Percentage value={(value/row.original.sent)*100}/></span>;
          },
        },
        {
          Header: (
            <div className="flex csCenter">
              <UilEnvelopeReceive
                size={18}
                style={{ marginTop: 2, marginRight: 3 }}
              />
              Replies
            </div>
          ),
          accessor: 'replies',
          disableSortBy: true,
          Cell: ({ row, value }) => {
            return <span className="csCenter">{value} <Percentage value={(value/row.original.sent)*100}/></span>;
          },
        },
      ];

      setColumns(cols);
    }
  }, [sequence,sent,opens,linkClicks,replies]);

  async function fetchSequence() {
    try {
      let result = await Axios.get(`/api/sequences/${id}`);
      let _sequence = result?.data?.data;
      if (
        !_sequence.flowchart?.nodes ||
        _sequence.flowchart?.nodes?.length === 0
      )
        return (window.location.href = `/outreach/sequences/${id}`);
      setSequence(result?.data?.data);
    } catch (err) {
      console.log('Error in fetching sequence ', err);
    }
  }

  async function fetchSequenceStats() {
    try {
      console.log(id);
      let result = await Axios.get(`/api/sequences/${id}/stats`);

      setTableData(result.data.data);
      setTotalLeads(result.data.sequenceDate?.leads);
      setSent(result.data.sequenceDate?.sent);
      setScheduled(result.data.sequenceDate?.scheduled);
      setOutbox(result.data.sequenceDate?.outbox);
      setOpens(result.data.sequenceDate?.opens);
      setLinkClicks(result.data.sequenceDate?.link_clicks);
      setReplies(result.data.sequenceDate?.replies);
      setBounces(result.data.sequenceDate?.bounced);
      setUnsubscribed(result.data.sequenceDate?.unsubscribed);
      setLoading(false);
    } catch (err) {
      console.log('Error in fetching sequence stats ', err);
    }
  }

  return (
    <div>
      <Helmet>
        <title>{`Sequence Stats | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <CustomSubNav
        selected={'sequences'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />

      {loading && (
        <div
          className="csFormLoader"
          style={{
            background: `rgba(255, 255, 255, 0.5)`,
            marginTop: 70,
            height: '105vh',
            zIndex: 2,
          }}
        >
          <Loader>
            <b className="font-bold">Loading Sequence Stats. Please wait...</b>
          </Loader>
        </div>
      )}

      {!loading && (
        <div className="sequenceStats">
          <div id='stats-heading' style={{ display: 'flex' }}>
            <div style={{ marginLeft: 15 }}>
              <PageTitle
                title={
                  <span style={{ fontWeight: 400 }}>
                    Stats for{' '}
                    <span style={{ fontWeight: 500 }}>{sequence?.name}</span>
                  </span>
                }
                description="A quick glance at your sequence stats."
                // tooltip="A quick glance at your sequence stats."
              />
            </div>
            <div className="flex" id='logs-buttons'>
              <button
                id="show-reports"
                className="inline-block flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                type="button"
                style={{ outline: '3px solid #8929E0', width: 40, height: 40 }}
                onClick={() => {
                  window.location.href = `/outreach/sequences/${id}/reports`;
                }}
              >
                <UilArrowBreak
                  size={30}
                  style={{ marginTop: 3 }}
                  fill="#8929E0"
                />
              </button>
              <button
                id="show-logs"
                className="inline-block flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                type="button"
                style={{ outline: '3px solid #ED317F', width: 40, height: 40 }}
                onClick={() => {
                  window.location.href = `/outreach/reports?sequence=${sequence?.id}`;
                }}
              >
                <UilHistory size={30} style={{ marginTop: 3 }} fill="#ED317F" />
              </button>
              <button
                id="edit-sequence"
                className="inline-block flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                type="button"
                style={{ outline: '3px solid #F9C00C', width: 40, height: 40 }}
                onClick={() => {
                  window.location.href = `/outreach/sequences/${id}`;
                }}
              >
                <UilEdit size={30} style={{ marginTop: 3 }} fill="#F9C00C" />
              </button>
            </div>
            <Tooltip anchorSelect="#show-reports">View Step wise Stats</Tooltip>
            <Tooltip anchorSelect="#show-logs">View Sequence Logs</Tooltip>
            <Tooltip anchorSelect="#edit-sequence">Edit Sequence</Tooltip>
          </div>
          <Row style={{ padding: '0 15px', marginTop: 10 }}>
            <Col lg={7}>
              <WarmUpChart sequence={id} />
            </Col>
            <Col lg={5}>
              <Row>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Leads"
                    value={totalLeads || 0}
                    icon={<UilUsersAlt size={35} color="#0066ff" />}
                    style={{
                      borderColor: '#9EC7F1',
                      backgroundColor: '#E8F0F8',
                    }}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Sent"
                    value={sent || 0}
                    icon={<UilEnvelopeCheck size={35} color="#3AC569" />}
                    style={{
                      borderColor: '#89DCA5',
                      backgroundColor: '#D3EFDC',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=outreach`}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Scheduled"
                    value={scheduled || 0}
                    icon={<UilClock size={35} color="#F9C00C" />}
                    style={{
                      borderColor: '#FBD355',
                      backgroundColor: '#F3E8C4',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=outreach`}
                  />
                </Col>
                {sequence?.checkEmailBeforeSending && (
                  <Col lg={6} className="mb-1">
                    <StatBox
                      title="Outbox"
                      value={outbox || 0}
                      icon={<UilEnvelope size={35} color="#03A6FF" />}
                      style={{
                        borderColor: '#93CCF3',
                        backgroundColor: '#EAF1F7',
                      }}
                      href={`/outreach/outbox?sequenceID=${id}`}
                    />
                  </Col>
                )}
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Opens"
                    value={opens || 0}
                    percent={(opens/sent)*100}
                    icon={<UilEnvelopeOpen size={35} color="#1BE1C9" />}
                    style={{
                      borderColor: '#51E3D1',
                      backgroundColor: '#E3F6F4',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=open`}
                    showTooltip={true}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Link Clicks"
                    value={linkClicks || 0}
                    percent={(linkClicks/sent)*100}
                    icon={<UilLink size={35} color="#8929E0" />}
                    style={{
                      borderColor: '#B67AEC',
                      backgroundColor: '#E3CCF8',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=click`}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Replies"
                    value={replies || 0}
                    percent={(replies/sent)*100}
                    icon={<UilEnvelopeReceive size={35} color="#FC913A" />}
                    style={{
                      borderColor: '#FDBD89',
                      backgroundColor: '#FED3B0',
                    }}
                    href={`/inbox?sequence=${sequence?.id}&tab=replies`}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Bounces"
                    value={bounces || 0}
                    percent={(bounces/sent)*100}
                    icon={<UilEnvelopeTimes size={35} color="#ED317F" />}
                    style={{
                      borderColor: '#F484B2',
                      backgroundColor: '#FBD6E5',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=bounce`}
                  />
                </Col>
                <Col lg={6} className="mb-1">
                  <StatBox
                    title="Unsubscribed"
                    value={unsubscribed || 0}
                    percent={(unsubscribed/totalLeads)*100}
                    icon={<UilEnvelopeTimes size={35} color="#E53A40" />}
                    style={{
                      borderColor: '#EF898C',
                      backgroundColor: '#F5D3D4',
                    }}
                    href={`/outreach/reports?sequence=${sequence?.id}&event=unsubscribed`}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} className="mt-2">
              <h1 style={{ fontSize: 20, fontWeight: 500, marginBottom: 10 }}>
                Stats per Email Sender
              </h1>
              {columns.length > 0 && (
                <CustomTable
                  hasFooter={false}
                  columns={columns}
                  data={tableData}
                  style={{ width: '100%' }}
                  onClick={(row) => {}}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function StatBox({ title, value, href, icon, style,percent, showTooltip }) {
  return (
    <div className="statCard">
      <div
        className="csBadge"
        style={{ padding: 15, border: '2px solid', ...style }}
      >
        {icon}
      </div>
      <div className="m-auto">
        <div className="statCount">
          {value}
          <Percentage value={percent} className={'statPercent'}/>
        </div>
        <div className="statTitle flex">
          {href ? (
            <a href={href} target="_blank" className="csCustomeLink">
              {title} <UilExternalLinkAlt size={16} className="csLinkIcon" />
            </a>
          ) : (
            title
          )} {showTooltip && <UilInfoCircle size={18} style={{ marginRight: 3 }} className='ml-2' id='show-wrong-opens-reason' />}
        </div>
      </div>
    </div>
  );
}
